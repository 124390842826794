<template lang="">
    <div class="row">
        <div class="col-6 list" 
            v-for="(item, index) in list" :key="index">
            <router-link
                :key="index"
                :to="{ name: 'lesson.detail', params: { lessonId: item.id } }"
                class="no-decoration"
                >
                <div class="ranking">
                    <label v-if="item.rank">{{ item.rank }}</label>
                </div>
                <div class="cimg">
                    <img :src="item.thumbnail">
                </div>
                <div class="description text-center">
                    <h5>
                        <span class="expert" v-if="item.is_expert==1">
                            専門家
                        </span> 
                        {{ item.teacher_name }}
                    </h5>
                    <div class="introduction custom-text-overflow">
                        {{ item.title }}
                    </div>
                    <div class="price text-left">
                        {{ formatCurrency(item.currency_code, item.price) }}
                    </div>
                    <div class="reviewed-star rate text-left">
                        <span class="star">
                            <star-rating
                                :rating="Number(item.rated)"
                                :star-size="18"
                                :show-rating="false"
                                :read-only="true"
                            />
                        </span>
                        <span>
                            {{ item.rated }}
                            {{ item.reviewed ? `(${item.reviewed})` : `` }}
                        </span>
                    </div>
                </div>
            </router-link>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        list: {
            default: []
        }
    }
}
</script>